import React, { Component, Fragment } from 'react'
import { withOktaAuth } from '@okta/okta-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import IconUserSignIn from '../../globals/images/icon_person_user--avatar.svg'
import IconUserSignInMobile from '../../globals/images/icon_person_user--avatar-m.svg'
import LogInAPI from '../api/LoginAPI'
import HomeUrlAPI from '../api/HomeUrlAPI'
import { FF_LOGIN_AUTH_JWT_TOKEN_ENABLED } from '../../constants'

export default withLDConsumer()( withOktaAuth( class LogIn extends Component  {

    constructor( config ) {
        super()
        this.state = {
            isUserLogged : false,
            urlToRedirect : '',
            config : { ...config }
        }
    }

    get config() {
        return this.props?.config || this.state.config
    }
    
    get logOutUrl() {
        return this.getKubraLogoutUrl || `${ window.location.origin }${ process.env.REACT_APP_LOG_OUT_URL }` 
    }

    get getHomeUrl() {
        return this.config.homeUrl || process.env.REACT_APP_BASE_URL
    }

    get getKubraLogoutUrl() {
        const logoutUrl =  this.config.kubraLogoutUrl || ''
        if( logoutUrl ){
            return `${ window.location.protocol }//${ this.config.kubraLogoutUrl }`
        } 
        return ''
    }

    get getOrigin() {
        const url = new URL( this.getHomeUrl )
        return url.origin
    }

    get getLoggedInUserNameUrl () {
        return `${ window.location.origin }${ process.env.REACT_APP_GET_USER_API_URL }` 
    }

    get getDefaultReturnUrl () {
        const origin = ( window.location.origin  === this.getOrigin ) ? '' : this.getOrigin 
        return `${ origin }${ process.env.REACT_APP_DEFAULT_RETURN_URL }`
    }

    get getOriginPattern () {
        return `${ window.location.origin }${ process.env.REACT_APP_LOG_IN_URL }`
    }

    get getHomeReturnUrl () {
        return HomeUrlAPI( { apiUrl: this.getHomeUrl } )
        .then( ( result ) => {
             return ( !result?.Data ) ? this.getDefaultReturnUrl : result?.Data
        } )
    }

    handleLogout = async ( e ) => {
        e.preventDefault( )
        const { ldClient } = this.props
        if ( ldClient.allFlags()[ FF_LOGIN_AUTH_JWT_TOKEN_ENABLED ] ) {
            await this.props.oktaAuth.signOut( { postLogoutRedirectUri: this.logOutUrl } )
            return
        }
        window.location.href = this.logOutUrl        
    }

    async componentDidMount () {
        this._isMounted = true
        this.setState( { urlToRedirect : `${ this.getOriginPattern }?ReturnUrl=${ this.getDefaultReturnUrl }` } )
        LogInAPI( { apiUrl: this.getLoggedInUserNameUrl } ).then( ( data ) => {
            // detects if user is logged or if it is in a kubra domain
            if( ( this._isMounted && data.id ) || this.getKubraLogoutUrl.includes( window.location.host ) )
                this.setState( { isUserLogged : true } )
        } )
        const returnUrl = await this.getHomeReturnUrl
        this.setState( { urlToRedirect : `${ this.getOriginPattern }?ReturnUrl=${ returnUrl }` } )
    }

    componentWillUnmount() {
        this._isMounted = false
    }
    
    render() {
        const adaMsg = this.state.isUserLogged ? 'sign out' : 'sign in / sign up'
        return (
            <Fragment>
                <a  href={ this.state.urlToRedirect } className={ this.state.isUserLogged ? 'signin-box-off': 'signin-box' } 
                    tabIndex = { 0 } aria-label = { adaMsg } id="signInLink">
                    <figure className="signin-box__user-box-icon">
                        <img src={ IconUserSignIn } className="user-icon"></img>
                        <img src={ IconUserSignInMobile } className="user-icon-m" height="20px" width="20px"></img>
                    </figure>
                    <span className="signin-box__sign-in-sign-up"> sign in / sign up </span>
                    <span className="signin-box__sign-in-sign-up-m"> sign in </span>
                </a>
                <a href={ this.logOutUrl } onClick={ this.handleLogout } className={ this.state.isUserLogged ? 'signout-box': 'signout-box-off' } 
                    tabIndex = { 0 } aria-label = { adaMsg } data-url={ this.logOutUrl }>
                    <figure className="signout-box__user-box-icon">
                        <img src={ IconUserSignIn } className="user-icon"></img>
                        <img src={ IconUserSignInMobile } className="user-icon-m" height="20px" width="20px"></img>
                    </figure>
                    <span className="signout-box__signout"> sign out </span>
                </a>
            </Fragment>
        )
    }

    static propTypes = {
        config: PropTypes.shape( {
            homeUrl: PropTypes.string,
            kubraLogoutUrl: PropTypes.string
        } ),
        oktaAuth: PropTypes.shape( {
            signOut: PropTypes.func
        } ),
        ldClient: PropTypes.instanceOf( Object )
      }
} ) )
